<template>
  <div class="absolute-center">
    <div class="auth-inner">
      <v-progress-circular
        :width="3"
        color="#1bb098"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import { getUser } from '@api/user'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router, route } = useRouter()

    onMounted(async () => {
      const isUserAbilitySet = store.getters['user/getAbility'] !== null

      if (!isUserAbilitySet) {
        const user = await getUser()

        if (user) {
          const { ability } = user

          store.dispatch('user/setAbility', ability)

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.ability

          store.dispatch('user/setProfile', user)

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(ability)
        }
      }

      if (route.value.query?.redirect) {
        return router.push({
          path: route.value.query.redirect,
        })
      }

      const role = store.getters['user/getRole']

      if (role) {
        if (role === 'admin') {
          return router.push({
            name: 'dashboard-crm',
          })
        }

        // if (role === 'client') {
        //   return router.push({
        //     name: 'page-access-control',
        //   })
        // }
      }
    })

    return {}
  },
}
</script>

<style lang="scss" scoped>
.absolute-center {
  display: flex;
  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;
}
</style>
